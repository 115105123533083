
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";

@Component({
  components: {},
})
export default class UpdateEmailAndPhone extends Vue {
  public currentData = null;
  public phoneExist = false;
  public emailExist = false;
  public noChange = false;
  /**
   * Delete Confirmation
   */
  public async updateData(body) {
    try {
      this.currentData = body;
      if (body.phone) await this.$modal.show("updatePhone");
      else await this.$modal.show("updateEmail");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * UserChoice
   */
  public async updateEmail() {
    try {
      if (this.noChange) {
        this.$snotify.info("No Changes");
        this.$modal.hide('updateEmail')
        this.noChange = false;
        return;
      }
      //console.log(this.currentData, 'curr')
      const response = await Axios.post(
        BASE_API_URL + "common/updateEmailOfUser",
        this.currentData,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        await this.$snotify.success(response.data.message);
        this.$modal.hide("updateEmail");
        this.$emit("call-mount-funtion");
      } else {
        console.log(response.data.emailExist, "ds");
        this.emailExist = response.data.emailExist ? true : false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async updatePhone() {
    try {

      if (this.noChange) {
        this.$snotify.info("No Changes");
        this.$modal.hide('updatePhone')
        this.noChange = false;
        return;
      }

      const response = await Axios.post(
        BASE_API_URL + "common/updatePhoneOfUser",
        this.currentData,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        await this.$snotify.success(response.data.message);
        this.$modal.hide("updatePhone");
        this.$emit("call-mount-funtion");
      } else {
        this.phoneExist = response.data.phoneExist ? true : false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public removeValidation() {
    this.emailExist = false;
    this.phoneExist = false;
    // console.log( this.emailExist)
  }
}
