
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import { Processor } from "../../../models/super-admin/processor.model";
import CommonDeleteModal from "../../../common-components/CommonDeleteModal.vue";
import UpdateEmailAndPhone from "../../../common-components/UpdateEmailAndPhone.vue";
import CommonUserChoiceModal from "../../../common-components/CommonUserChoiceModal.vue";
import AddressComponent from "../../../common-components/AddressComponent.vue";
import Axios from "axios";
import { authHeader } from "../../../services/auth";
import Multiselect from "vue-multiselect";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

@Component({
  components: {
    CommonDeleteModal,
    AddressComponent,
    Multiselect,
    CommonUserChoiceModal,
    UpdateEmailAndPhone,
    PerfectScrollbar,
  },
})
export default class ManageProcessor extends Vue {
  /* eslint-disable */
  public processorData: any = new Processor();
  public processorList: any = [];
  public disclosureList = [];
  public postClosingList = [];
  public juniorList = [];
  public roles = [];
  public passwordFieldType = "password";
  public proImage = null;
  public myName = null;
  public selectedProcessorIndex: any = null;
  public isEdit = false;
  public skip = 0;
  public totalLength = 0;
  public currentLengthOnScroll = 0;
  public name: any = null;
  public isReset = false;
  public AllocatedLoanData = [];
  public showLoader = false;

  public assigneeList: any = [];

  public async saveProcessor() {
    this.$store.state.tmpcoLoader = true;
    try {
      this.processorData.assigneeList = this.assigneeList;
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/processor/saveProcessor`,
        this.processorData,
        { headers: authHeader() }
      );

      if (response.status === 201) {
        this.$snotify.success(response.data.message);
        this.cancel();
        this.getProcessorList(true);
      } else {
        this.$snotify.error(response.data.message);
        this.processorData.emailExist = response.data.emailExist;
        this.processorData.phoneExist = response.data.phoneExist;
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async resendLinkToProcessor(data) {
    try {
      const processordata = {
        email: data.email,
        userId: data._id,
        name: data.name,
      };
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/processor/resendLinkToProcessor`,
        processordata,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.$snotify.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getProcessorList(mountCall) {
    mountCall
      ? (this.$store.state.tmpcoLoader = true)
      : (this.showLoader = true);

    try {
      this.skip = mountCall ? 0 : this.skip + 15;

      let path = "";
      path = "super-admin/processor/getProcessorList";
      const response = await Axios.get(BASE_API_URL + path, {
        params: {
          name: this.isReset ? null : this.name,
          options: {
            skip: this.skip,
            limit: 15,
          },
        },
        headers: authHeader(),
      });
      if (response.status === 201) {
        this.isReset = false;
        if (mountCall) {
          this.processorList = response.data.promise
            ? response.data.promise
            : [];
        } else {
          const data = response.data.promise ? response.data.promise : [];
          this.processorList = this.processorList.concat(data);
        }
        this.totalLength = response.data.ProcessordataLength;
        this.currentLengthOnScroll = this.processorList
          ? this.processorList.length
          : 0;
        // this.$emit("total-loan", this.totalLength);
        // if (this.processorList.length > 0) {
        //   for (let i = 0; i < this.processorList.length; i++) {
        //     const e = this.processorList[i];
        //     e.selectedRole = e.selectedRoles[0];
        //   }
        // }
      }
    } catch (error) {
      this.isReset = false;
    }
    mountCall
      ? (this.$store.state.tmpcoLoader = false)
      : (this.showLoader = false);
  }

  public async getprofileImage(data) {
    this.$store.state.tmpcoLoader = true;
    try {
      const processordata = {
        userId: data._id,
      };
      const response = await Axios.post(
        `${BASE_API_URL}common/my-account/getprofileImage`,
        processordata,
        { headers: authHeader() }
      );

      if (response.data.profileImageData.profileImage) {
        this.proImage = response.data.profileImageData.profileImage;
        this.myName = response.data.profileImageData.name;
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public loadImage(path) {
    try {
      setTimeout(function () {
        const dom = document.getElementById("imageEl");
        const img = document.createElement("img");
        img.src = path;
        img.id = "img-id";
        if (dom) {
          dom.innerHTML = "";
          dom.appendChild(img);
        }
      }, 10);
    } catch (error) {
      console.log(error);
    }
  }

  public cancel() {
    this.$modal.hide("addProcessor");
    this.processorData = new Processor();
    this.$modal.hide("assignRole");
    this.assigneeList = [];
    this.$modal.hide("emailPassword");
  }

  public async scrollHandle(evt) {
    let scroll: any = this.$refs.scrollbar;
    if (
      !this.showLoader &&
      scroll.ps.reach.y == "end" &&
      this.currentLengthOnScroll < this.totalLength
    ) {
      await this.getProcessorList(false);
    }
  }

  public async deleteProcessor(selectedUser) {
    try {
      console.log(selectedUser, "selectedUser");
      await this.$refs.ref["delConfirmationBox"]({
        msg: `Are you sure want to delete this ${selectedUser.name} Processor ?`,
        data: { id: selectedUser._id },
        apiPath: "super-admin/processor/deleteProcessor",
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async getJuniorProcessorList() {
    this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/processor/getJuniorProcessorList`,
        { headers: authHeader() }
      );

      if (response.status === 201) {
        response.data.juniorList.forEach((j) => {
          j["fullName"] = `${j.fullName.firstName} ${
            j.fullName.middleName ? j.fullName.middleName : ""
          } ${j.fullName.lastName}`;
        });
        response.data.disclosureList.forEach((j) => {
          j["fullName"] = `${j.fullName.firstName} ${
            j.fullName.middleName ? j.fullName.middleName : ""
          } ${j.fullName.lastName}`;
        });
        response.data.postClosingList.forEach((j) => {
          j["fullName"] = `${j.fullName.firstName} ${
            j.fullName.middleName ? j.fullName.middleName : ""
          } ${j.fullName.lastName}`;
        });
        this.disclosureList = response.data.disclosureList;
        this.juniorList = response.data.juniorList;
        this.postClosingList = response.data.postClosingList;
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async changeStatus(index) {
    try {
      const selectedUser = this.processorList[index];
      const status = selectedUser.isUserActive ? "Activate" : "De Activate";
      await this.$refs.refrence["confirmationBox"]({
        msg: `Are you sure want to ${status} the ${selectedUser.name} Processor ?`,
        data: {
          userId: selectedUser._id,
          status: selectedUser.isUserActive,
        },
        apiPath: "super-admin/processor/activateDeActivateProcessor",
      });
    } catch (error) {
      console.log(error);
    }
  }

  public removeValidation() {
    this.processorData.emailExist = false;
    this.processorData.phoneExist = false;
  }

  public async getRoles(inform = false) {
    // this.$store.state.tmpcoLoader = true;
    try {
      const data = inform
        ? {}
        : {
            processorUserId:
              this.processorList[this.selectedProcessorIndex]._id,
          };
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/processor/getRoles`,
        data,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.roles = response.data;
      }
    } catch (error) {
      console.log(error);
    }
    // this.$store.state.tmpcoLoader = false;
  }

  public whileAssigning(role, event) {
    try {
      if (event.target.checked)
        this.processorList[this.selectedProcessorIndex].roleId.push(role.id);
      else
        this.processorList[this.selectedProcessorIndex].roleId =
          this.processorList[this.selectedProcessorIndex].roleId.filter(
            (e) => e.toString() !== role.id.toString()
          );
    } catch (error) {
      console.log(error);
    }
  }

  public async assignRoleToProcessor() {
    this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/processor/assignRoleToProcessor`,
        this.processorList[this.selectedProcessorIndex],
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.$snotify.success(response.data.message);
        this.$modal.hide("assignRole");
        this.getProcessorList(true);
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async updateEmailAndPhone(selectedUser, type) {
    try {
      const data =
        type === "Phone"
          ? { phone: selectedUser.phone }
          : { email: selectedUser.email };
      await this.$refs.REF["updateData"]({
        ...data,
        userId: selectedUser._id,
      });
    } catch (error) {
      console.log(error);
    }
  }

  // public removeRole(event, processorData) {
  //   try {
  //     event.value === 3 ? (processorData.postClosingAssistant = []) : null;
  //     event.value === 4 ? (processorData.disclosureSpecialist = []) : null;
  //     event.value === 2 ? (processorData.juniorProcessor = []) : null;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  public async getAllocatedLoanData(data) {
    try {
     if(data.numberOfLoan=='No Loan Allotted') {
                return;
     }
      const processordata = {
        userData: data.loanData,
      };
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/processor/getAllocatedLoanData`,
        processordata,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.AllocatedLoanData = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    }
 
 public async changeEmailPassword(){
    this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/processor/changeEmailPassword`,
        this.processorData,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.$modal.hide("emailPassword");
        this.$snotify.success(response.data.message);
        // this.$modal.hide("emailPassword");
        this.getProcessorList(true);
      }
      else{ 
        this.$snotify.error(response.data.message);
        this.$modal.hide("emailPassword");
        this.getProcessorList(true);
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;

 }
  // public async getAssigneeList() {
  //   try {
  //     const response = await Axios.post(
  //       `${BASE_API_URL}super-admin/processor/getAssigneeList`,
  //       { processorId: this.processorData._id },
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //     if (response.status === 201) {
  //       this.assigneeList = response.data;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  mounted() {
    this.getProcessorList(true);
    // this.getAssigneeList();
  }
}
